import React from 'react';
import Page from '../components/Page';
import Layout from '../components/Layout';
import useStoryblok from '../utils/storyblok';

export default function PageIndex({ pageContext }) {
  const story = useStoryblok(pageContext.story);

  return (
    <Layout>
      <Page
        blok={story.content}
        languages={pageContext.languages}
        language={pageContext.language}
        slugs={pageContext.slugs}
        slug={pageContext.slug}
        links={pageContext.links}
        popups={pageContext.popups}
      />
    </Layout>
  );
}
